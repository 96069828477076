import request from '@/utils/request'

/**
 * 登录 获取验证码
 */
export const login = {
    postLogin(data) {
        return request({
            url: '/public/login/admin/phone',
            method: 'post',
            data,
        })
    },
    getCode(data) {
        return request({
            url: '/public/sms/send',
            method: 'post',
            data,
        })
    },
}

export const logout = {
    postLogout() {
        return request({
            url: '/public/login/logout',
            method: 'post',
        })
    },
}