import request from '@/utils/request'

/**
 * 省市区
 */
export const province = {
    getProvince() {
        return request({
            url: 'v/equipment/pressure/v2/province',
            method: 'get',
        })
    },
}
