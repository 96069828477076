<template>
  <div class="login-container" id="index" ref="appRef">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">大良卿实时数据分析平台</h3>
      </div>
      <!-- 手机号 -->
      <el-form-item prop="phone">
        <span class="svg-container">
          <i class="el-icon-mobile-phone"></i>
        </span>
        <el-input
          ref="phone"
          v-model.trim="loginForm.phone"
          placeholder="手机号"
          name="phone"
          maxlength="11"
          oninput="value=value.replace(/[^0-9]/g,'')"
          tabindex="1"
          auto-complete="on"
        />
        <div
          v-show="show"
          @click="getCodeBtn"
          type="plain"
          size="mini"
          class="btnCode"
        >
          获取
        </div>
        <div v-show="!show" type="plain" size="mini" class="btnCode">
          {{ count + "S" }}
        </div>
      </el-form-item>
      <!-- 验证码 -->
      <el-form-item prop="code">
        <span class="svg-container">
          <i class="el-icon-message"></i>
        </span>
        <el-input
          ref="code"
          v-model.trim="loginForm.code"
          placeholder="验证码"
          name="code"
          maxlength="6"
          oninput="value=value.replace(/[^0-9]/g,'')"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
      </el-form-item>
      <el-form-item
        class="desensitization"
        style="border: none; background: none"
      >
        <el-checkbox-group
          v-model="desensitization"
          @change="handleDesensitization"
        >
          <el-checkbox label="是否数据脱敏"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- 登录按钮 -->
      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin"
      >
        登 录
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { login } from "@/api/login";
import { province } from "@/api/province.js";
import { Message } from "element-ui";
import { validateCode, validatePhone } from "@/utils/index";
import drawMixin from "@/utils/drawMixin";

export default {
  mixins: [drawMixin],
  data() {
    return {
      desensitization: false,
      address: [],
      // 表单验证
      loginRules: {
        phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
      },
      loginForm: {
        phone: "",
        code: "",
        appType: "BIG_MAP",
        extraParams: '{"sysUserType":"BD"}',
        dlqDomain: true,
      },
      loading: false,
      redirect: undefined,
      show: true,
      count: "",
      timer: null,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    handleDesensitization(type) {
      sessionStorage.setItem("desensitization", type);
    },
    // 获取验证码按钮
    getCodeBtn() {
      const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
      if (this.loginForm.phone.length <= 0) {
        return this.$message.error("请填写手机号码！");
      } else if (!reg.test(this.loginForm.phone)) {
        return this.$message.error("请填写正确的手机号码！");
      } else {
        // 发送请求
        login
          .getCode({ phone: Number(this.loginForm.phone), smsType: "LOGIN" })
          .then((res) => {
            if (res.code !== "OK") {
              return;
            } else {
              // 定时器 60s
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.show = true;
                    // 清除定时器
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            }
          });
      }
    },
    // 登录按钮
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          login.postLogin(this.loginForm).then((res) => {
            if (res.code === "OK") {
              this.getProvince();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getProvince() {
      province.getProvince().then((res) => {
        let districtsTree = res.data.districts;
        // this.address = [];
        // this.handleTreeName(districtsTree)
        // sessionStorage.setItem("province", JSON.stringify(this.address));
        if (res.code === "NO_AUTH" || res.code === "FORBIDDEN") {
          window.localStorage.clear();
          window.sessionStorage.clear();
          Message.error("没有权限登录,请联系管理员");
        } else {
          this.$router.push({
            path: "/ecg",
          });
        }
      });
    },
    handleTreeName(tree) {
      if (tree.length) {
        this.address.push(tree[0].name);
        this.handleTreeName(tree[0].districts)
      }
    },
  },

  // 清除定时器
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  width: 100%;
  height: 100%;
  min-height: 1080px;
  min-width: 1980px;
  // background-color: $bg;
  background: url(~@/assets/login_bg.png) no-repeat center;
  background-size: 100% 100%;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 450px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 200px 0 0 15%;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 50px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  .btnCode {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    // opacity: 0.3;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .desensitization {
    display: flex;
    padding-left: 6px;
  }
}
</style>
